<template>
  <FormInputWidget
    :id="inputId"
    :notRequired="notRequired"
    :label="label" :hideLabel="hideLabel"
    :hasError="hasError" :errorMsg="errorMsg"
  >
    <template v-if="$slots.label" #label="{ label, notRequired }">
      <slot name="label" :label="label" :notRequired="notRequired" />
    </template>

    <FormInputSelect :id="inputId" :notRequired="notRequired" :options="options" :optionLabel="optionLabel" :disabled="disabled" :optionValue="optionValue" :value="value" @input="emitInput" />
  </FormInputWidget>
</template>

<script>

import formInputWidgetMixin from '../mixins/formInputWidget';
import formSelectPropsMixin from '../mixins/formSelectProps';

export default {
  mixins: [formInputWidgetMixin, formSelectPropsMixin],
  components: {
    FormInputSelect: () => import('../FormInputSelect'),
  },
};

</script>
